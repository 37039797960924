google.charts.load('current', {'packages':['corechart']});
google.charts.setOnLoadCallback(drawPieChart);

function drawPieChart() {

  var data = google.visualization.arrayToDataTable([
    ['CA', "en miliers d'euros"],
    ['Market',     11],
    ['Informatique',      7],
    ['Ingénierie',  15],
  ]);

  var options = {
    title: "Chiffre d'Affaires (en milliers d'euros):"
  };

  var chart = new google.visualization.PieChart(document.getElementById('piechart'));

  chart.draw(data, options);
}


google.charts.load('current', {'packages':['corechart']});
google.charts.setOnLoadCallback(drawChart);

function drawChart() {
  var data = google.visualization.arrayToDataTable([
    ['Year', 'Etudes signées'],
    ['2014', 12],
    ['2015',8],
    ['2016',19],
    ['2017',15],
    ['2018',31]
  ]);

  var options = {
    title: 'Etudes signées',
    hAxis: {title: 'Year',  titleTextStyle: {color: '#333'}},
    vAxis: {minValue: 0}
  };

  var chart = new google.visualization.AreaChart(document.getElementById('chart_div'));
  chart.draw(data, options);
}